'use strict';
var MAX_EMAILS_COUNT = require('./max-emails-count');
var DRAFT_LIFE_TIME_DAYS = require('./draft-life-time').IN_DAYS;
var MAX_FILE_SIZE_PER_SUBMISSION = require('./file-upload').max_file_size_per_submission / 1024;
var MAX_OWNERSHIP_TRANSFER_SIZE = require('./file-upload').max_ownership_transfer_size;
var MAX_ATTACHMENT_SIZE_SINGLE_SUBMISSION = require('./file-upload').max_file_size / 1024;
var MAX_BULK_EXPORT = require('./lei-products').MAX_BULK_EXPORT;
var MAX_SEARCH_OFFSET = require('./lei-products').MAX_SEARCH_OFFSET;
const MINIMUM_AUTO_RENEW_BILLME = require('./lei-products').MINIMUM_AUTO_RENEW_BILLME;
const AUTO_RENEW_WARNING_DAYS = require('./lei-products').MINIMUM_AUTO_RENEW_DAYS;
const MAX_SHOPPING_CART_SIZE = require('./lei-products').MAX_SHOPPING_CART_SIZE;

var dataMap = {
  default: {
    error: 'An error occured. Please check your input or contact administrator.'
  },
  service: {
    error: 'There was an issue with data service. Please try again later or contact administrator for help. '
  },
  uriError: {
    error: 'There is an issue with the link you provided. Please try again later or contact administrator for help. '
  },
  existingUser: {
    error: 'This username is already taken. Please try a different username.'
  },
  existingEmail: {
    error: 'This email is already in user account. Please try a different email.'
  },
  exportAutoRenewExcel: {
    error: 'Failed to bulk get auto renew records from BBDS. Please contact administrator for help.'
  },
  tooLong: {
    error: 'Your input is not valid.'
  },
  regex: {
    error: 'Your input is not valid.'
  },
  invalidInput: {
    error: 'Your input is not valid.'
  },
  authenticate: {
    error: 'This feature requires being logged in. If you were previously logged in, your session may have timed out.',
    success: 'You are authenticated.'
  },
  authorize: {
    error: 'You are not authorized for this feature at this moment. Please contact support if you have questions. ',
    success: 'You are authorized.'
  },
  locked: {
    error:
      'Your account has been locked. We have just sent an unlock key to your login e-mail. Please use it to unlock your account.'
  },
  unlockKey: {
    error:
      'Your account has been locked. We could not generate an unlock key using the credentials you provided. Please try at the log in page. '
  },
  unlock: {
    error: 'We could not unlock your account. Please check your unlock key, try again, or contact administrator.',
    success: 'Your account has been unlocked. Please log in again.'
  },
  passwordResetKey: {
    error: 'Password reset key could not be generated. Please check your account name or email address.',
    success:
      'A password reset e-mail has been sent to the e-mail address below if it is indeed registered to the username account you specified. '
  },
  resetPassword: {
    error: 'Password could not be reset.',
    success: 'Password has been reset. Please log in with new password.'
  },
  sentUsername: {
    error: 'Username could not be sent to the requested email.',
    success:
      'We have received your request. If this email has one or more usernames associated with it, you will receive an email shortly. Please log in with sent username.'
  },
  login: {
    error: 'We could not log you in. Wrong username, email or password. ',
    success: 'Please validate your e-mailed two-factor login code. '
  },
  validate2factorCode: {
    error: 'Your two-factor login code was invalid. Please try again.',
    success: 'Your two-factor login code was valid.'
  },
  validate2factorCodeExpired: {
    error: 'Your two-factor login code was invalid. Please log in again.'
  },
  redirectedToLogout: {
    error: 'This feature requires being logged in. If you were previously logged in, your session may have timed out.',
    success: 'You are logged out. '
  },
  signup: {
    error: 'We could not create your account. Did you fill all the requierd fields in correct format? ',
    success:
      'You are signed up. Please log in with your username and password to submit requests such as creating a new LEI.'
  },
  updateProfile: {
    error: 'We could not update your profile. Did you fill all the fields in correct format? ',
    success: 'Your profile has been updated. '
  },
  addEmail: {
    error: 'We could not add this email. ',
    success: 'Email added successfully.'
  },
  blockEmail: {
    error: 'This email is currently considered a blocked email and therefore is restricted.'
  },
  'blockEmail.account': {
    error: 'This email is currently considered a blocked email and therefore cannot be used for signup and/or login.'
  },
  'blockEmail.apikey': {
    error: 'This email is currently considered a blocked email and therefore apikey cannot be generated.'
  },

  zeroAttachmentsSize: {
    error: 'One of the attachments has zero size. Please check that there are no empty files being attached.'
  },
  tooManyEmails: {
    error: `There are too many emails under this account. You can add up to ${MAX_EMAILS_COUNT} emails (including your own).`
  },
  removeEmail: {
    error: 'We could not remove this email. ',
    success:
      'Email removed successfully. Auto renew notifications that used this email will now be sent to another email from the same account.'
  },
  domain: {
    error: 'You must sign-up with a corporate e-mail address. Third party e-mail domains are not allowed.',
    success: 'This email domain is accepted. '
  },
  activate: {
    error: 'We could not activate this user. ',
    success: 'This user account has been activated. '
  },
  deactivate: {
    error: 'We could not deactivate this user. ',
    success: 'This user account has been deactivated. '
  },
  bulkDuplicate: {
    error: 'There is a duplicate instrument that exists for the data you are submitting. '
  },
  bulkInvalid: {
    error: 'There is invalid information found in the data you are submitting.'
  },
  invalidExcelVersion: {
    error:
      'Out-of-date or invalid Excel workbook template detected, please download the latest Excel workbook template and try again.'
  },
  attachmentsNeeded: {
    error: 'There are missing attachments: %(attachments)s. Please check the requirement in attachment section.'
  },
  attachmentsNotNeeded: {
    error: 'There are extra attachments: %(attachments)s. Please check the attachment column in the Excel file.'
  },
  attachmentsTooMany: {
    error: 'There are too many attachments. Please check the requirement in attachment section.'
  },
  attachmentsTooLarge: {
    error: 'The total size of the attachments is too large. Please check the requirement in attachment section.'
  },
  submissions: {
    error: 'Unable to get all submissions data.',
    success: 'Here are all submissions.'
  },
  bulkSubmission: {
    error: 'Your submission was not successful. Please review errors. ',
    success: 'Your bulk submission has completed.  Please review the results and pay below.'
  },

  bulkUserCreate: {
    error: 'Your submission was not successful. Please review errors. ',
    success: 'Your bulk user account creation has completed.'
  },

  offlineSubmission: {
    error: 'Your bulk submission was not successful. Please review errors. ',
    success: 'Your Excel file has been accepted. You should receive email regarding its submission status soon.'
  },

  bulkRenew: {
    error:
      'Your bulk renew submission was not successful. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Please review the results and pay below.'
  },

  bulkUpdate: {
    error:
      'Your bulk submission update was not successful. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Please keep the tracking number(s) %(submissionIds)s for your record.'
  },

  bulkTransfer: {
    error: 'Your bulk submission was not successful. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Please keep the tracking number(s) %(submissionIds)s for your record.'
  },

  bulkTransferRenew: {
    error: 'Your bulk submission was not successful. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Please keep the tracking number(s) %(submissionIds)s for your record.'
  },

  bulkRequestViaShoppingCart: {
    error: 'Your bulk submission was not successful. Please try again or contact the support team for assistance.'
  },

  publishBulk: {
    error: 'Your bulk submission was not successful. Please try again or contact the support team for assistance.',
    success: 'Your bulk submission has completed.  Please review the results and pay below.'
  },

  submissionNotFound: {
    error:
      'Unable to find this submission. Please make sure you provided the correct reference number, or contact administrator.'
  },
  notSubmissionOwner: {
    error:
      'Unable to find this submission. Please make sure you provided the correct reference number, you can find it in My Request page or contact administrator.'
  },
  noFile: {
    error: 'There is no file selected. '
  },
  recaptcha: {
    error: 'ReCaptcha verification failed. Please visit the "Why am I unable to proceed?" topic in our FAQ page. '
  },
  recaptchaRefresh: {
    error:
      'ReCaptcha verification failed. Please refresh your browser and retry or visit the "Why am I unable to proceed?" topic in our FAQ page.'
  },
  getTaxForCountry: {
    error: 'There was an issue with tax data service. Please try again later or contact administrator for help. '
  },
  updateTaxForCountry: {
    error:
      'There was an issue with calculating tax and price. Please try again later or contact administrator for help. '
  },
  vatRequired: {
    error: 'Please provide VAT/GST if you are an entrepreneur/business entity or choose different entity type.'
  },
  stripeCallInternal: {
    error:
      'There was an issue with processing your credit card. Please try again later or contact administrator for help. '
  },
  redirectedAfterLogin: {
    success: 'You were redirected to the page you intended after logging in.'
  },
  feedback: {
    error: 'We could not submit the contact request. Please fill in all fields.',
    success: 'Your contact request has been submitted.'
  },
  schemaNotFound: {
    error: 'Cannot find schema version. Please check your input or contact administrator.'
  },
  upgradeSchemaVersions: {
    error: 'Cannot upgrage schema version. Please check your input or contact administrator.',
    success: 'Schema version is upgraded. '
  },
  saveNote: {
    error: 'Cannot save note. Please check with administrator.',
    success: 'Your note is saved. '
  },
  sendAdminMessage: {
    error: 'Cannot send message. Please check with administrator.',
    success: 'Your message is sent. '
  },
  getFacilitators: {
    error: 'Unable to get facilitator(s) information.  Please check with administrator.'
  },
  createFacilitator: {
    error: 'There was a problem creating the facilitator.  Please check with administrator.'
  },
  updateFacilitator: {
    error: 'There was a problem updating the facilitator.  Please check with administrator.'
  },
  deleteFacilitator: {
    error: 'There was a problem deleting the facilitator.  Please check with administrator.'
  },
  addCard: {
    error: 'Cannot add new card. Please check with administrator.',
    success: 'Your new card is added. '
  },

  fillInRequired: {
    error: 'Please fill in required fields.'
  },

  postalCodeRequired: {
    error: 'A domicile postal code is required for the selected country.'
  },

  notLeiOwner: {
    error: 'The selected LEI belongs to another account holder.'
  },

  notLeiOwnerBulk: {
    error:
      'The LEI `%s` belongs to another account holder. If you feel this record needs to be updated, please submit a challenge.'
  },

  invalidLeiBulk: {
    error: 'Failed to find LEI %s. Please check your input and try again or contact the support team for assistance.'
  },

  invalidLeiBulkTransfer: {
    error:
      "Failed to find LEI %s in the GLEIF's registry. Please check your input and try again or contact the support team for assistance."
  },

  notLeiWorkflowOwner: {
    error:
      'Please provide valid submission tracking number. You can find it in My Request page or contact the support team for assistance.'
  },

  invalidLei: {
    error: 'Please provide valid LEI id. You can find it in search page or contact the support team for assistance.'
  },

  lapsedLei: {
    error:
      'Update on lapsed LEI is not allowed. Please renew it. You can find its detail in search page or contact the support team for assistance.'
  },

  leiNotTransferable: {
    error: 'The LEI `%s` cannot be transferred because only ISSUED, LAPSED, or EXPIRED LEIs can be transferred.'
  },

  leiMustBeTransferRenewed: {
    error:
      'LEI is lapsed or going to be lapsed within 10 days. Please renew it. You can find its detail in search page or contact the support team for assistance.'
  },

  validateSubmissionFormSection: {
    error:
      'Failed to validate the submission form section. Please try again or contact the support team for assistance.'
  },

  leiActionDefault: {
    error: 'Failed to process your LEI request. Please try again or contact the support team for assistance.'
  },

  newLei: {
    error: 'Failed to process your LEI submission. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Please pay below.'
  },

  updateLei: {
    error: 'Failed to process your LEI update request. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Your tracking number is %(submissionId)s.'
  },

  transferLei: {
    error: 'Failed to process your LEI transfer request. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Your tracking number is %(submissionId)s.'
  },

  challengeLei: {
    error: 'Failed to process your LEI challenge request. Please try again or contact the support team for assistance.',
    success: 'Your submission has completed. Your tracking number is %(submissionId)s.'
  },

  challengeLeiNonBB: {
    error:
      'The submitted LEI is not managed by Bloomberg and can therefore not be challenged on the Bloomberg LEI website.'
  },

  challengeLeiOwn: {
    error: "You own the LEI you're trying to challenge. Submit an Update or Renew application instead."
  },

  renewLei: {
    error: 'Failed to process your LEI renewal request. Please try again or contact the support team for assistance',
    success: 'Your submission has completed. Please pay below.'
  },

  transferRenewLei: {
    error:
      'Failed to process your LEI renewal request for this transfer. Please try again or contact the support team for assistance',
    success: 'Your renewal request for this transfer has completed. Please pay below.'
  },

  createLei: {
    error: 'Failed to process your LEI submission. Please try again or contact the support team for assistance',
    success: 'Your submission has completed. Please pay below.'
  },

  saveDraft: {
    error: 'Failed to save your submission draft. Please try again or contact the support team for assistance.',
    success: `Your submission draft has been saved and will be stored locally in your browser. It will be kept for ${DRAFT_LIFE_TIME_DAYS} days or until submitted. You can find your drafts in the My Requests page.`
  },

  draftExpire: {
    error: `Failed to load your submission draft as it has expired. Drafts are kept for ${DRAFT_LIFE_TIME_DAYS} days or until submitted.`
  },

  loadDraft: {
    error: 'Failed to load your submission draft. Please try again or contact the support team for assistance.'
  },

  invalidParentLEI: {
    error: 'Please provide valid parent LEI. Please try again or contact the support team for assistance'
  },

  paymentBulkBILLME: {
    success:
      'Your request has been processed successfully. You should receive an email regarding your bulk submission status soon.',
    error: 'Failed to process your request. Please try again or contact the support team for assistance.'
  },

  paymentBILLME: {
    success: 'Your request has been processed successfully. Your tracking numbers are %(submissionId)s.',
    error: 'Failed to process your request. Please try again or contact the support team for assistance.'
  },

  paymentBulkCREDIT: {
    success:
      'Your credit card is processed successfully. You should receive an email regarding your bulk submission status soon.',
    error: 'Failed to make payment. Please try again or contact the support team for assistance.'
  },

  paymentCREDIT: {
    success: 'Your credit card is processed successfully. Your tracking numbers are %(submissionId)s.',
    error: 'Failed to make payment. Please try again or contact the support team for assistance.'
  },

  cardDeclined: {
    error:
      'Your card has been declined by the issuing bank. Please try again using a different card or contact the bank. You can always contact the support team for assistance.'
  },

  loadStripe: {
    error:
      'Could not contact the card service provider, please make sure "*.stripe.com" is not being blocked by your IT settings.'
  },

  addNewCard: {
    error: 'Failed to add new credit card to your account. Please try again or contact the support team for assistance.'
  },

  paymentUserInput: {
    error: 'Sorry, we can not verify your credit card. Please provide correct billing address and credit card cvc.'
  },

  expiredCard: {
    error:
      'Sorry, your credit card has expired. Please use a different credit card and try again. Or contact the support team for assistance.'
  },

  invalidExpMonth: {
    error:
      "Your card's expiration month is invalid. Please correct it and try again. Or contact the support team for assistance."
  },

  getLeis: {
    error: 'Failed to search for any LEI data. Please try again or contact the support team for assistance.'
  },

  getLei: {
    error:
      'This LEI does not seem to be managed by Bloomberg. If you wish to renew or update the LEI with Bloomberg please submit a transfer request.'
  },

  getGleifLei: {
    error: 'Failed to process your LEI request. Please try again or contact the support team for assistance.'
  },

  getGleifLeiInvalid: {
    error: 'Invalid LEI identifier. Please check your input and try again or contact the support team for assistance.'
  },

  getLeiWorkflow: {
    error: 'Failed to find the data. Please try again or contact the support team for assistance.'
  },

  getLeiWorkflowUserError: {
    error: 'Failed to find the data. Please check your input and try again or contact the support team for assistance.'
  },

  getCardsFromStripe: {
    error: 'Failed to find your existing credit cards. Please try again or contact the support team for assistance.'
  },

  publicEmailError: {
    error: 'Public email provider addresses cannot be used. Please enter your institutional email instead.'
  },

  cannotRemoveOwnEmail: {
    error: 'Cannot remove your own email.'
  },

  userLoginEmailMismatch: {
    error: 'Username or login email does not match our record. Please check your username or email.'
  },

  useOldPassword: {
    error:
      'New password matches old password. Please create another one and try again or contact support for assistance.'
  },

  xmlInvalidType: {
    error: 'Found invalid xml type. It should only contain alphanumerics, dashes, or underscores.'
  },

  xmlInvalidFileName: {
    error: 'Found invalid xml file name. It should only contain alphanumerics, dashes, or underscores.'
  },

  xmlFileNotFound: {
    error:
      'This XML file was not found. It may have been expired or updated with a new URL. Please go to or refresh Downloads page to find an XML file to download.'
  },

  invalidPayment: {
    error:
      'Invalid payment information. Please follow the instruction on payment page or contact the support team for assistance.'
  },

  verifyPayment: {
    error:
      'No submission data or payment information not found. Please follow the instruction on payment page or contact the support team for assistance.'
  },

  deleteCard: {
    success: 'Card deleted successfully.',
    error: 'No payment information found. Please try again or contact the support team for assistance.'
  },
  deleteCardAutoRenew: {
    error:
      'This card has been used for an auto renew setup. Please update all auto renewals setup with this card to delete this card.'
  },
  vatRequiredAutoRenew: {
    error:
      'VAT is required for the domicile country associated with your payment information. Please add your VAT/GST number for correct invoicing and try again. To view your full payment information please go your profile payment page. If you are an individual or business without VAT please contact the support team for further instructions.'
  },
  vatRequiredAutoRenewBILLME: {
    error:
      'We could not complete your auto renew setup as a VAT number is required for the domicile country associated with your payment information. Please choose to pay by credit card or contact the support team for assistance.'
  },
  updateCard: {
    success: 'Card updated successfully.',
    error: 'No payment information found. Please try again or contact the support team for assistance.'
  },

  noUserFound: {
    error: 'No such user found. Please try again or contact support team for assistance.'
  },

  noUsersByEmailFound: {
    error: 'No such email found. Please try again or contact support team for assistance.'
  },

  exportExcel: {
    error: 'There was an error while exporting to Excel. Please try again or contact support team for assistance.'
  },

  exportExcelBulk: {
    error: 'There was an error while exporting to Excel. Please try again or contact support team for assistance.'
  },

  validateExcelForTransfer: {
    error:
      'You can only submit transfers in bulk for either LEIs with a registration status of ISSUED and not going to be LAPSED within 10 days, or which are already LAPSED/going to be lapsed within 10 days, but not both at the same time. Please try again or contact support team for assistance.'
  },

  BBleiNotTransferable: {
    error: 'The LEI `%s` cannot be transferred because it is already owned by Bloomberg.'
  },

  noDataInExcel: {
    error: 'No LEIs data found in Excel. Please try again or contact support team for assistance.'
  },

  exportGleifExcelBulk: {
    error: 'There was an error while exporting to Excel. Please try again or contact support team for assistance.'
  },

  exportFixedExcelBulk: {
    error: 'There was an error while exporting to Excel. Please try again or contact support team for assistance.'
  },

  maxFileUploadSize: {
    error: `Maximum total size of all attachments per submission allowed exceeded. The upper limit is ${MAX_FILE_SIZE_PER_SUBMISSION} KB.`
  },

  referenceDataValue: {
    error:
      'Your bulk submission was not successful. Please use the values for country, region, entity legal form code and authority id from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  referenceDataValueBulk: {
    error:
      'Your bulk submission was not successful due to LEI %d. Please use the values for country, region, entity legal form code and authority id from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  referenceDataRegAuthValue: {
    error:
      'Your bulk submission was not successful. Please use the values for authority id from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  referenceDataRegAuthValueBulk: {
    error:
      'Your bulk submission was not successful due to LEI %d. Please use the values for authority id from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  referenceDataRegionValue: {
    error:
      'Your bulk submission was not successful. Please use the values for region in all addresses from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  referenceDataRegionValueBulk: {
    error:
      'Your bulk submission was not successful due to LEI %d. Please use the values for region in all addresses from reference sheets provided on the website. Try again or contact the support team for assistance.'
  },

  duplicateLEIForBulk: {
    error:
      'The LEIs #%d and #%d are both `%s`. Please remove the duplicate and try again or contact support team for assistance.'
  },

  alreadyOwned: {
    error: 'Some of the LEIs are already under the same user accounts.'
  },

  notOwnedOwnershipTransfer: {
    error: 'The LEI `%s` is not owned by %s.'
  },

  emailNotFoundOwnershipTransfer: {
    error: 'The LEI `%s` is not a valid email for %s.'
  },

  selfOwnershipTransfer: {
    error: 'The user is already the owner in row %d. Have you tried submitting an update instead?'
  },

  fileFormatOwnershipTransfer: {
    error: 'There is an error in row %d. Please verify your file is a proper `.csv`.'
  },

  maxSizeOwnershipTransfer: {
    error: `No more than ${MAX_OWNERSHIP_TRANSFER_SIZE} LEIs can be transferred at a time.`
  },

  bulkOwnershipTransfer: {
    success: 'Successfully processed LEI ownership transfer.',
    error: 'Failed to process LEI ownership transfer.'
  },

  leiNotFoundOwnershipTransfer: {
    error: "The LEI `%s` was not found. Please make sure it's correct or contact the support team for assistance."
  },

  mandatoryOwnershipTransfer: {
    error: 'The %s in row %s is missing, this field is required.'
  },

  maxAttachmentsSizeSingle: {
    error: `Maximum total size of all attachments for a single submission allowed exceeded. The upper limit is ${MAX_ATTACHMENT_SIZE_SINGLE_SUBMISSION} KB.`
  },

  tooManyLEIs: {
    error: `There are too many records being exported. The upper limit is ${MAX_BULK_EXPORT}.`
  },

  autoRenew: {
    error: 'Your auto renew request was not successful. Please try again or contact the support team for assistance.',
    success: 'Your auto renew request has been processed successfully.'
  },

  autoRenewBatch: {
    error: 'Your auto renew request was not successful. Please try again or contact the support team for assistance.',
    success: 'Your auto renew request has been processed successfully.'
  },

  autoRenewAll: {
    error: 'Your auto renew request was not successful. Please try again or contact the support team for assistance.',
    success: 'Your auto renew request has been processed successfully.'
  },

  invalidStatusForRenew: {
    error:
      'In order to renew a LEI, the registration status must be ISSUED, LAPSED, PENDING_TRANSFER or PENDING_ARCHIVAL.'
  },

  invalidAutoRenewDate: {
    error: `Auto renewal date of %s is less than ${AUTO_RENEW_WARNING_DAYS} days before or is beyond the closest next renew date %s of the selected LEIs, such as %s.`
  },

  minimumAutoRenewBillMe: {
    error: `The minimum number of LEIs for auto renew with "Invoice Me/Pay Later" option is ${MINIMUM_AUTO_RENEW_BILLME}.`
  },

  minimumAutoRenew: {
    error: 'No LEIs are found in order to set up auto renew.'
  },

  duplicateLEIAutoRenew: {
    error: 'Auto renewal has already been set up for this LEI.'
  },

  invalidOffset: {
    error: `We don't support search for LEIs further down. The upper limit is ${MAX_SEARCH_OFFSET}.`
  },

  authorizationForm: {
    error: 'Please provide an authorization form.'
  },

  lessAuthorizationForm: {
    error: 'Please provide an authorization form for every submission.'
  },

  moreAuthorizationForm: {
    error: 'Please provide only one authorization form per submission.'
  },

  referenceFile: {
    error: 'Please provide a reference file.'
  },

  noAutoRenewForSome: {
    error: 'Unable to cancel the auto renewals since you have not set up auto renew for some of the LEIs yet.'
  },

  noAutoRenew: {
    error: 'Unable to cancel the auto renewal since you have not set up auto renew for the LEI yet.'
  },

  missingAutoRenewDates: {
    error: 'Unable to set up auto renewals since a renewal date is not available for the selected LEIs.'
  },

  noPaymentRecordForAutoRenewBillMe: {
    error:
      'Unable to choose payment type of "Invoice Me/Pay Later" for auto renewals since no past payment records are found.'
  },

  addToCart: {
    error: 'We could not process your request. Please try again or contact support team.',
    success: 'The request is successfully added into cart, please check out or continue to submit new requests.'
  },

  addressValidation: {
    error: 'We could not verify the domicile address entered. Please verify and try again.'
  },

  shoppingCart: {
    error: 'Mixed paid and free types of LEI requests in shopping cart is not supported.'
  },

  shoppingCartDup: {
    error: 'Adding different cart items with the same LEI in shopping cart is not allowed.'
  },

  cartItemExist: {
    error:
      'There is already a cart item with the same LEI in shopping cart. Please use shopping cart to submit the request.'
  },

  shoppingCartSize: {
    error: `The max number of items allowed in shopping cart is ${MAX_SHOPPING_CART_SIZE}.`
  },

  multiparty: {
    error: 'There is an issue with your attachment. Please try again or contact support team.'
  },

  attachmentExt: {
    error: 'The accepted file externsions for attachments are %s. Please try again or contact support team.'
  },

  'api.version': {
    error: 'The selected version `%s` in not permitted. Please choose a valid version %s.'
  },

  'api.create': {
    error: 'Failed to process create LEI submission. Please try again or contact support team.'
  },

  'api.create.events': {
    error:
      'Failed to process create LEI submission.  Legal entity events are not supported for this type of submission.'
  },

  'api.deleteCart': {
    error: 'Failed to delete the submission or the cart. Please try again or contact support team.'
  },

  'api.lei': {
    error: 'Failed to get LEI data. Please make sure you provide a valid LEI and try again or contact support team.'
  },

  'api.lock': {
    error: 'Failed to process your LEI API request. Please try again or contact support team.'
  },

  'api.renew': {
    error: 'Failed to process renew LEI submission. Please try again or contact support team.'
  },

  'api.status': {
    error: 'Failed to get status for your LEI request. Please try again or contact support team.'
  },

  'api.schema': {
    error: 'Failed to get schema. Please try again or contact support team.'
  },

  'api.transfer': {
    error: 'Failed to process transfer LEI request. Please try again or contact support team.'
  },

  'api.transferRenew': {
    error: 'Failed to process transfer-renew LEI request. Please try again or contact support team.'
  },

  'api.update': {
    error: 'Failed to process update LEI submission. Please try again or contact support team.'
  },

  'api.upload': {
    error: 'Failed to upload files for LEI request. Please try again or contact support team.'
  },

  'api.verify': {
    error: 'Failed to authorize LEI request. Please try again or contact support team.'
  }
};

module.exports = dataMap;
